<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_proDockSchool_page relative w1240">
			<div class="analysis_sys_category_con">
				<div class="analysis_sys_category_title fontsize22">地域分类</div>
				<div class="analysis_sys_category_list">
					<div class="ul list1">
						<div class="li item1" :class="{'isopen':item1.isopen}" v-for="(item1,index1) in industryType" :key="index1">
							<div class="menu_title fontsize16">
								<span class="span" :class="{'hoverspan':industryIndex==index1}" @click="handleindustryType(index1)">{{item1.name}}</span>
								<div class="img" v-if="item1.children&&item1.children.length>0" @click="openindustryType(index1,null)">
									<img v-if="!item1.isopen" src="../assets/static/icon_add.png"/>
									<img v-if="item1.isopen" src="../assets/static/icon_reduce.png"/>
								</div>
							</div>
							<div ref="list2" class="ul list2 collapse-transition" v-if="item1.children&&item1.children.length>0">
								<div class="li item2" :class="{'isopen':item2.isopen}" v-for="(item2,index2) in item1.children" :key="index2">
									<div class="menu_title fontsize14 menu2_title">
										<span class="span">{{item2.name}}</span>
										<div class="img" v-if="item2.children&&item2.children.length>0" @click="openindustryType(index1,index2)">
											<img v-if="!item2.isopen" src="../assets/static/icon_add.png"/>
											<img v-if="item2.isopen" src="../assets/static/icon_reduce.png"/>
										</div>
									</div>
									<div :ref="'list3_'+index1+'_'+index2" class="ul list3 collapse-transition" v-if="item2.children&&item2.children.length>0">
										<div class="li item3" :class="{'isopen':item3.isopen}" v-for="(item3,index3) in item2.children" :key="index3">
											<div class="menu_title fontsize14 menu3_title">
												<span class="span">{{item3.name}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="proDockSchool_page_con">
				<!-- 内容，列表 -->
				<div class="proDockSchool_datalist" v-if="showType=='line'">
					<div class="thlist fontsize16">
						<div class="th1">序号</div>
						<div class="th2">名称</div>
						<div class="th3">专利申请量</div>
						<div class="th4">专利有效量</div>
						<div class="th5">机构属性</div>
						<div class="th6">重点研究领域</div>
					</div>
					<div class="tdlist">
						<div class="tditem fontsize16" v-for="(item,index) in dataList" :key="index">
							<div class="td1">{{item.id}}</div>
							<div class="td2 fontsize18">{{item.collegeName}}</div>
							<div class="td3">{{item.applyNum}}</div>
							<div class="td4">{{item.effectiveNum}}</div>
							<div class="td5">{{item.collegeAttr}}</div>
							<div class="td6">{{item.collegeField}}</div>
						</div>
					</div>
				</div>
				<div class="block30" v-if="dataList.length<=0"></div>
				<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="w-100 flexrow flexbetween">
					<div class="fontsize18">{{proDockSchoolRemarks || ""}}</div>
					<div class="phonenone" style="text-align:right">
					    <el-pagination
					          @current-change="handleCurrentChange"
					          :current-page.sync="currentPage"
					          :page-size="pageSize"
					          layout="prev, pager, next, jumper"
					          :total="total">
					    </el-pagination>
					</div>
					<div class="pcnone" style="text-align:right">
					    <el-pagination
						      small
					          @current-change="handleCurrentChange"
					          :current-page.sync="currentPage"
					          :page-size="pageSize"
					          layout="prev, pager, next"
					          :total="total">
					    </el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
			showType:"line",//展示形式，行列 非图文
			industryType:[],
			industryIndex:0,//下标
			liConHeight: 0, // 折叠面板内容初始高度
			liConHeight3: 0, // 折叠面板内容初始高度
			formobj:{
				collegeArea:null,
				collegeName:"",
			},
			proDockSchoolRemarks:"",//项目对接类型，院校 ，成果，专家这些
		};
	},
	mounted: function() {
		if(this.$route.query){
			if(this.$route.query.key){
				//console.log(this.$route.query.key)
				this.formobj.collegeName = this.$route.query.key
			}
			if(this.$route.query.collegeArea){
				this.formobj.collegeArea = this.$route.query.collegeArea
			}
		}
		//列表
		this.getPatentCollegeAddress()
		//获取类型
		this.getZiangmuduijieleixing()
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取类型
		getZiangmuduijieleixing() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 5
			};
			this.$http.post('frontEnd/ProjectResource/getZiangmuduijieleixing', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					var obj = records.find((item) => { //这里的options就是上面遍历的数据源
						return item.name == "科研院校"; //筛选出匹配数据
					});
					if(obj){
						_this.proDockSchoolRemarks = obj.remarks
					}else{
						if(records.length>0){
							_this.proDockSchoolRemarks = records[0].remarks
						}
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//点击行业分类
		handleindustryType(index){
			this.industryIndex = index
			this.handleCurrentChange(1)
		},
		//展开行业分类
		openindustryType(index1,index2){
			//console.log(index1,index2)
			if(index2!=null&&index2>-1){
				//点击二级
				const isopen = this.industryType[index1].children[index2].isopen
				this.industryType[index1].children[index2].isopen = !isopen
				const liCon3 = this.$refs[`list3_${index1}_${index2}`][0]
				var height3 = liCon3.offsetHeight
				if (height3 === this.liConHeight3) { // 展开
				    liCon3.style.height = 'auto'
				    height3 = liCon3.offsetHeight
				    liCon3.style.height = this.liConHeight3 + 'px'
				    // eslint-disable-next-line no-unused-vars
				    let f = document.body.offsetHeight // 必加
				    liCon3.style.height = height3 + 'px'
				} else { // 收缩
				    liCon3.style.height = this.liConHeight3 + 'px'
				}
			}else{
				//点击一级
				const isopen = this.industryType[index1].isopen
				this.industryType[index1].isopen = !isopen
				const liCon = this.$refs.list2[index1]
				var height = liCon.offsetHeight
				//console.log(height)
				if (height === this.liConHeight) { // 展开
				    liCon.style.height = 'auto'
				    height = liCon.offsetHeight
				    liCon.style.height = this.liConHeight + 'px'
				    // eslint-disable-next-line no-unused-vars
				    const f = document.body.offsetHeight // 必加
				    liCon.style.height = height + 'px'
					setTimeout(function(){
						liCon.style.height = 'auto'
					},300)
				} else { // 收缩
				    console.log("收缩")
					if(height>0){
						liCon.style.height = height + 'px'
					}
					const f = document.body.offsetHeight // 必加
				    liCon.style.height = this.liConHeight + 'px'
				}
			}
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getdataList()
		},
		//获取(文章)科研院校地点
		getPatentCollegeAddress() {
			var _this = this;
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 88
			this.$http.post('frontEnd/ProjectResource/getPatentCollegeAddress', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data;
					var collegeAreaList = [
						{
							id:0,
							name:"全部不限",
							isopen:false,
						}
					]
					records.forEach((item,index)=>{
						var obj = {
							id:index+1,
							name:item,
							isopen:false,
						}
						collegeAreaList.push(obj)
					})
					_this.industryType = collegeAreaList
					//获取(文章)科研院校
					_this.getdataList()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//获取(文章)科研院校
		getdataList() {
			var _this = this;
			var params = this.formobj
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			if(this.industryIndex==0){
				params["collegeArea"] = null
			}else{
				params["collegeArea"] = this.industryType[this.industryIndex].name
			}
			console.log(params)
			this.$http.post('frontEnd/ProjectResource/getPatentCollege', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						// if(item.contents){
						// 	item["body"] = _this.$util.filtersText(item.contents,318)
						// 	item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						// }
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
